<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div @click="$router.go(-1)" class="flex cursor-pointer items-center">
      <div class="p-2 border border-opacity-25 rounded-md border-blue-500">
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </div>
      <div class="ml-2 text-sm text-black">Back</div>
    </div>
    New Terminal
    <form @submit.prevent="createTerminal">
      <div class=" grid grid-cols-2 gap-5 mt-10">
        <div class="flex flex-col pt-4">
          <label for="email" class="text-xs">Terminal ID *</label>
          <input
            type="text"
            required
            v-model="payload.terminal_id"
            class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>

        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Terminal Provider ID *</label>
          <select
            type="text"
            required
            v-model="payload.terminal_provider_id"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          >
            <option :value="null" disabled> Select Terminal Provider</option>
            <option
              v-for="providers in terminalProviders"
              :value="providers._id"
              :key="providers._id"
            >
              {{ providers.name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Terminal Type</label>
          <select
            type="text"
            required
            v-model="payload.terminal_type"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled> Select Terminal Provider</option>
            <option value="linux">Linux</option>
            <option value="android">Android</option>
            <option value="mpos">Mpos</option>
          </select>
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">BANK *</label>
          <select
            type="text"
            required
            v-model="payload.bank_id"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          >
            <option :value="null" disabled> Select Bank </option>
            <option v-for="bank in banks" :value="bank._id" :key="bank._id">
              {{ bank.name }}
            </option>
          </select>
        </div>

        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Serial Number</label>
          <input
            type="text"
            required
            placeholder="Serial Number"
            v-model="payload.serial_no"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Mac Address:</label>
          <input
            type="text"
            placeholder="Mac Address"
            v-model="payload.mac_address"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>

        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Merchant ID</label>
          <!-- <small style="font-size:0.6em" class="mt-1 text-red-600"
          >Start by typing the first two letters of the merchant's name</small
        > -->
          <div>
            <div
              @click.stop="businessDropdown = true"
              class="shadow cursor-pointer appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
            >
              {{ businessName || "Select Merchant" }}
              <!-- {{business.name ? business._id === payload.business_id : ""}} -->
              <span class="inline-loading" v-if="isLoadingInline"></span>
            </div>
            <div
              v-if="businessDropdown"
              class="max-h-64 h-auto max-w-sm w-full overflow-y-auto bg-white mt-1 shadow border p-2 rounded-md"
            >
              <input
                required
                @click.stop
                v-on:keyup="handleBusinessIDSearch"
                type="text"
                v-model="merchantID"
                class="shadow appearance-none text-xs border rounded w-3/4 py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
              <button
                @click="handleBusinessIDSearch"
                type="button"
                class="uppercase shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
              >
                Search
              </button>
              <div
                class="mt-2"
                v-for="business in filterAsyncBusiness"
                :key="business._id"
              >
                <div class="capitalize cursor-pointer" @click="send(business)">
                  {{ business.name }}
                  <span class="text-sm lowercase">({{ business.email }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Store ID</label>
          <div>
            <div
              @click.stop="storeDropdown = true"
              class="shadow cursor-pointer appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
            >
              {{ storeName || "Select Store" }}
            </div>
            <div
              v-if="storeDropdown"
              class=" max-h-64 h-auto max-w-sm w-full overflow-y-auto bg-white mt-1 shadow border p-2 rounded-md"
            >
              <input
                required
                @click.stop
                type="text"
                v-model="storeSearch"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
              />
              <div
                class="mt-2"
                v-for="store in filterStore"
                :key="store.store_id"
              >
                <div class="capitalize" @click="sendStore(store)">
                  {{ store.store_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Merchant Phone:</label>
          <input
            type="text"
            placeholder="Merchant Phone"
            v-model="payload.mac_address"
            disabled
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div> -->
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Merchant email:</label>
          <input
            type="text"
            placeholder="Merchant email"
            v-model="businessInfo.email"
            disabled
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Merchant Name:</label>
          <input
            type="text"
            placeholder="Merchant Name"
            disabled
            v-model="businessInfo.name"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Sim</label>
          <input
            required
            type="text"
            placeholder="Phone"
            v-model="payload.sim"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Isp</label>
          <input
            required
            type="text"
            v-model="payload.isp"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Traction Rep</label>
          <input
            type="text"
            v-model="payload.traction_rep"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Activation Code</label>
          <input
            type="text"
            v-model="payload.activation_code"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
      <button
        type="submit"
        class="block uppercase shadow bg-blue-600 mt-10  leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
      >
        Create
      </button>
    </form>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  data() {
    return {
      businessDropdown: false,
      storeDropdown: false,
      stores: [],
      isLoading: false,
      terminalProviders: [],
      banks: [],
      storeName: null,
      businessInfo: {},
      businesses: [],
      search: "",
      storeSearch: "",
      businessName: "",

      payload: {
        terminal_provider_id: "",
        terminal_id: "",
        terminal_type: "",
        mac_address: "",
        serial_no: "",
        business_id: "",
        bank_id: "",
        sim: "",
        isp: "",
        traction_rep: "",
        activation_code: "",
      },
      merchantID: "",
      asyncBusiness: [],
      isLoadingInline: false,
    };
  },
  directives: {
    ClickOutside,
  },
  computed: {
    // businessName() {
    //   let existingName = this.businesses.find(
    //     (business) => business.business_id === this.payload.business_id
    //   );
    //   if (existingName) {
    //     return existingName.name;
    //   }
    // },

    filterBusiness() {
      return this.businesses.filter((business) =>
        business.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    filterStore() {
      return this.stores.filter((store) =>
        store.store_name.toLowerCase().includes(this.storeSearch.toLowerCase())
      );
    },
    filterAsyncBusiness() {
      return this.asyncBusiness.filter((business) =>
        business.name.toLowerCase().includes(this.merchantID.toLowerCase())
      );
    },
  },
  methods: {
    send(x) {
      this.stores = [];
      this.stores = x.stores;
      this.businessName = x.name;
      this.businessInfo = x;
      this.payload.business_id = x._id;
      this.businessDropdown = false;
    },
    sendStore(x) {
      // this.payload.business_id = x.store_id;
      this.storeName = x.store_name;
      this.storeDropdown = false;
    },
    hide() {
      this.search = "";
      this.businessDropdown = false;
    },
    async createTerminal() {
      if (this.payload.business_id == "") {
        alert("Enter Business Id");
        return;
      }
      let res = await this.$store.dispatch("CREATE_TERMINAL", this.payload);

      if (res.status) {
        this.$router.push({ name: "Terminals" });
      } else {
        alert(res.error);
      }
    },
    async handleBusinessIDSearch() {
      // if (this.merchantID.length === 2) {
      this.businessDropdown = true;
      this.isLoadingInline = true;
      let res = await this.$store.dispatch(
        "GET_BUSINESS_SEARCH_ASYNC",
        this.merchantID
      );
      this.isLoadingInline = false;

      if (res.status) {
        this.asyncBusiness = res.data;
      } else {
        alert(res.error);
      }
      // }
    },
  },
  async created() {
    this.isLoading = true;

    // let businesses = await this.$store.dispatch("GET_ALL_BUSINESSES");
    // this.businesses = []; //businesses.data.items;

    let bank = await this.$store.dispatch("GET_ALL_BANKS");
    this.banks = bank.data.items;
    let providers = await this.$store.dispatch("GET_ALL_TERMINAL_PROVIDERS");
    this.terminalProviders = providers.data.items;
    this.isLoading = false;
  },
};
</script>

<style scoped>
/* Absolute Center Spinner */
.inline-loading {
  /* position: fixed; */
  z-index: 999;
  /* height: 0.3em; */
  /* width: 0.3em; */
  display: block;
  float: right;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.inline-loading:before {
  /* content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 16rem;
  width: 100%;
  height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.3); */
}

/* :not(:required) hides these rules from IE9 and below */
.inline-loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.inline-loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
